import { Typography, Button, Box, Grid, MenuItem } from '@mui/material';
import {
  // TextInput,
  SelectInput,
  TextInput,
} from '../../layouts/FormElement';
import React, { useRef } from 'react';
import { format } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';
import { API_URL } from 'links/API_LINKS';
import axios from 'axios';
import { useQueryClient } from 'react-query';

const EditInvoice = ({ setValue, editInvoice, setEditInvoice }) => {
  const formikRef = useRef();
  const queryClient = useQueryClient();

  const activityArray = editInvoice.activity;
  // const latestActivity = activityArray[activityArray.length - 1];

  return (
    <Box>
      <Typography mb={2}>
        <Button onClick={() => setEditInvoice(null)}>Back</Button>
      </Typography>
      <Typography mb={2} variant='h5' color='primary'>
        Note: Following are the details of the Coach, you can only change the
        Invoice Status and Add Comment.
      </Typography>
      <Grid container gap={3}>
        <Grid item xs={3}>
          <Typography variant='h6'>
            IFSC Code:{editInvoice.bankDetails.IFSC_Code}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='h6'>
            UPI Id:{editInvoice.bankDetails.UPI_Id}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='h6'>
            Account Holder Name:{editInvoice.bankDetails.accountHolderName}
          </Typography>{' '}
        </Grid>
        <Grid item xs={3}>
          <Typography variant='h6'>
            Account Number:{editInvoice.bankDetails.accountNumber}
          </Typography>{' '}
        </Grid>
        <Grid item xs={3}>
          <Typography variant='h6'>
            Batch Name:{editInvoice.batchId.name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='h6'>
            Coach Name:{editInvoice.coachId.name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='h6'>
            Added On:{format(new Date(editInvoice.createdAt), 'do LLLL yyyy')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={3}>
          <Typography variant='h6'>Sessions:{editInvoice.sessions}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='h6'>
            Total Amount:{editInvoice.totalAmount}
          </Typography>
        </Grid>
        <>
          {activityArray?.map((activity, index) => {
            return (
              <Grid key={index} container gap={1} mb={0.2}>
                <Grid item xs={12} md={3}>
                  <Typography>
                    {index + 1}. Editor's Name: {activity.owner.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography>Editor's Comment: {activity.comment}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography>Changed Status: {activity.status}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography>
                    Updated On:
                    {format(new Date(editInvoice.updatedAt), 'do LLLL yyyy')}
                  </Typography>{' '}
                </Grid>
              </Grid>
            );
          })}
        </>
      </Grid>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          invoiceId: editInvoice._id,
          comment: '',
          status: editInvoice.status,
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let myData = { ...values };

          axios({
            method: 'PUT',
            url: API_URL + '/invoice',
            data: myData,
          })
            .then(function (response) {
              queryClient.invalidateQueries('fetchPendingInvoice');
              queryClient.invalidateQueries('fetchApprovedInvoice');
              queryClient.invalidateQueries('fetchPaidInvoice');

              setSubmitting(false);
              setEditInvoice(null);
              resetForm();
              setValue(0);
            })
            .catch(function (response) {
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              my={3}
            >
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='status'
                  label='Invoice Status'
                >
                  <MenuItem value='Pending'>Pending </MenuItem>
                  <MenuItem value='Paid'>Paid</MenuItem>
                  <MenuItem value='Approved'>Approved</MenuItem>
                </SelectInput>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='comment'
                  type='text'
                  label='Enter your comment'
                />
              </Grid>
            </Grid>

            <LoadingButton
              type='submit'
              variant='contained'
              loading={isSubmitting}
            >
              Edit Invoice
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditInvoice;
