import React from 'react';
import { Typography } from '@mui/material';

const EventInfo = (props) => {
  const meetingId = props.data['Meeting ID'];
  const eventName = props.data['Topic'];
  const eventDuration = props.data['Duration (Minutes)'];

  return (
    <div>
      <p>
        <Typography display='inline' variant='subtitle1'>
          Meeting ID:
        </Typography>
        {meetingId}
      </p>
      <p>
        <Typography display='inline' variant='subtitle1'>
          Event Name:
        </Typography>
        {eventName}
      </p>
      <p>
        <Typography display='inline' variant='subtitle1'>
          Duration:
        </Typography>
        {eventDuration} Minutes
      </p>
    </div>
  );
};

export default EventInfo;
