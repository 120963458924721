import React, { useState } from 'react';
import { Typography, Stack, Box } from '@mui/material';
import ReadCsv from './ReadCsv';
import AudienceAttendanceSummary from './AudienceAttendanceSummary';
import * as XLSX from 'xlsx';
import EventInfo from './EventInfo';

const UploadAttendance = ({
  eligibleUsers,
  eventId,
  snackbarActions,
  handleClose,
}) => {
  const [items, setItems] = useState([]);

  let attendance = [];
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      setItems(data);
    });
  };

  items.forEach((item) => {
    attendance.push({
      email: item['Topic'],
      duration: item['Start Time'],
    });
  });
  return (
    <div>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
        mt={4}
      >
        <Box>
          <Typography>Please upload XSLX file</Typography>
          <input
            type='file'
            onChange={(e) => {
              const file = e.target.files[0];
              readExcel(file);
            }}
          />
        </Box>
        OR
        <ReadCsv setCsvData={setItems} />
      </Stack>
      {items.length ? <EventInfo data={items[0]} /> : null}
      {items.length ? (
        <AudienceAttendanceSummary
          eventDuration={items[0]['Duration (Minutes)']}
          eventId={eventId}
          usersFromFile={attendance}
          eligibleUsers={eligibleUsers}
          snackbarActions={snackbarActions}
          handleClose={handleClose}
        />
      ) : null}
    </div>
  );
};

export default UploadAttendance;
