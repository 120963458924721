import React, { useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput, SelectInput } from '../../../../layouts/FormElement';
import { API_URL } from '../../../../../../links/API_LINKS';
import { LoadingButton } from '@mui/lab';
import { Grid, MenuItem, Typography, Stack } from '@mui/material';
import ShowUser from './ShowUser';
import UploadCsv from './UploadCsv';
import AddCertificateScore from './AddCertificateScore';

const AddNewUser = ({ batchData }) => {
  const queryClient = useQueryClient();
  const [serverResponse, setServerResponse] = useState();
  const fetchMentors = async (keys) => {
    const res = await axios.get(API_URL + '/mentor', {
      params: { limit: 0, sort: 'name', order: 'asc' },
    });
    return res;
  };

  const fetchCoaches = async (keys) => {
    const res = await axios.get(API_URL + '/coach', {
      params: { limit: 0, sort: 'name', order: 'asc' },
    });
    return res;
  };
  const fetchCompanies = async (keys) => {
    const res = await axios.get(API_URL + '/company', {
      params: { limit: 0, sort: 'name', order: 'asc' },
    });
    return res;
  };
  const fetchAreas = async (keys) => {
    const res = await axios.get(API_URL + '/area', {
      params: { limit: 0, sort: 'title', order: 'asc' },
    });
    return res;
  };
  const mentors = useQuery('fetchMentors', fetchMentors);
  const coaches = useQuery('fetchCoaches', fetchCoaches);
  const companies = useQuery('fetchCompanies', fetchCompanies);
  const areas = useQuery('fetchAreas', fetchAreas);
  const formikRef = useRef();

  function updateUserId(user) {
    const name = user.name;
    const email = user.email;
    const status = user.status || 'Active';
    const company = user.company ? user.company._id : '';
    const mentor = user.mentor ? user.mentor._id : '';
    const coach = user.coach ? user.coach._id : '';
    const area = user.area ? user.area._id : '60bf4ccd63dbccf5133ea70d';
    const completionStatus = user.completionStatus || 'Not Applicable';
    const company2 = user.company2 ? user.company2._id : null;
    const mentor2 = user.mentor2 ? user.mentor2._id : null;
    const coach2 = user.coach2 ? user.coach2._id : null;
    const certificateUrl = user.certificateUrl || '';

    if (formikRef.current) {
      formikRef.current.setFieldValue('userId', user._id);
      formikRef.current.setFieldValue('name', name);
      formikRef.current.setFieldValue('email', email);
      formikRef.current.setFieldValue('status', status);
      formikRef.current.setFieldValue('company', company);
      formikRef.current.setFieldValue('mentor', mentor);
      formikRef.current.setFieldValue('coach', coach);
      formikRef.current.setFieldValue('area', area);
      formikRef.current.setFieldValue('completionStatus', completionStatus);
      formikRef.current.setFieldValue('company2', company2);
      formikRef.current.setFieldValue('mentor2', mentor2);
      formikRef.current.setFieldValue('coach2', coach2);
      formikRef.current.setFieldValue('certificateUrl', certificateUrl);
    }
  }
  const findEmailInBatch = (allUsers, emailToCheck) => {
    return allUsers.find(
      (user) => user.email === emailToCheck.trim().toLowerCase()
    );
  };
  const submitForm = (values, setSubmitting, resetForm) => {
    axios({
      method: 'PUT',
      url: API_URL + '/batch/batch-users',
      data: values,
    })
      .then(function (response) {
        console.log(response);
        setServerResponse('User added');
        resetForm();
        queryClient.invalidateQueries(`fetchCurrentBatch${batchData._id}`);
      })
      .catch(function (response) {
        setServerResponse('Error! Could not process your request.');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  let mydata = [];
  if (
    coaches.status === 'success' &&
    mentors.status === 'success' &&
    areas.status === 'success' &&
    companies.status === 'success'
  ) {
    mydata = coaches.data.data.data.map((coach) => {
      return { coach: coach.name, coach_id: coach._id };
    });
    let datalength = mydata.length;
    for (var i = 0; i < mentors.data.data.data.length; i++) {
      if (i < datalength) {
        mydata[i]['mentor'] = mentors.data.data.data[i].name;
        mydata[i]['mentor_id'] = mentors.data.data.data[i]._id;
      } else {
        mydata.push({
          mentor: mentors.data.data.data[i].name,
          mentor_id: mentors.data.data.data[i]._id,
        });
      }
    }
    datalength = mydata.length;
    for (i = 0; i < areas.data.data.data.length; i++) {
      if (i < datalength) {
        mydata[i]['area'] = areas.data.data.data[i].title;
        mydata[i]['area_id'] = areas.data.data.data[i]._id;
      } else {
        mydata.push({
          area: areas.data.data.data[i].title,
          area_id: areas.data.data.data[i]._id,
        });
      }
    }
    datalength = mydata.length;
    for (i = 0; i < companies.data.data.data.length; i++) {
      if (i < datalength) {
        mydata[i]['company'] = companies.data.data.data[i].name;
        mydata[i]['company_id'] = companies.data.data.data[i]._id;
      } else {
        mydata.push({
          company: companies.data.data.data[i].name,
          company_id: companies.data.data.data[i]._id,
        });
      }
    }
  }
  return (
    <div>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          batchId: batchData._id,
          userId: null,
          name: '',
          email: '',
          company: '60b8883a69a1e0002266eb86',
          mentor: '60b8893369a1e0002266eb87',
          coach: '60b88a4969a1e0002266eb88',
          area: '60bf4ccd63dbccf5133ea70d',
          status: 'Active',
          completionStatus: 'Not Applicable',
          company2: null,
          mentor2: null,
          coach2: null,
          certificateUrl: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(2, 'Must be 2 characters or more')
            .max(100, 'Must be 100 characters or less')
            .required('Please enter a name'),
          email: Yup.string().email().required(),
          company: Yup.string(),
          mentor: Yup.string(),
          coach: Yup.string(),
          area: Yup.string().required(),
          status: Yup.string().required(),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setServerResponse('');
          if (!values.userId) {
            //User ID is not present, which means that edit button was not clicked
            //Check if the email address is already added in the batch
            if (findEmailInBatch(batchData.users, values.email)) {
              setServerResponse(
                'An account with this email already exists in the batch. Please edit the existing account.'
              );
              setSubmitting(false);
            } else {
              //If the user ID is not present(edit button not clicked) and  email address is not added in the batch yet
              submitForm(values, setSubmitting, resetForm);
            }
          } else {
            //Editing existing user which is already added in the batch
            submitForm(values, setSubmitting, resetForm);
          }
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  label='Name'
                  name='name'
                  type='text'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {values.userId ? (
                  <Typography>Email: {values.email}</Typography>
                ) : (
                  <TextInput
                    fullWidth
                    size='small'
                    label='Email'
                    name='email'
                    type='text'
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {companies.status === 'success' && (
                  <SelectInput
                    size='small'
                    name='company'
                    label='Company'
                    fullWidth
                  >
                    {companies.data.data.data.map((company) => {
                      return (
                        <MenuItem key={company._id} value={company._id}>
                          {company.name}
                        </MenuItem>
                      );
                    })}
                  </SelectInput>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {mentors.status === 'success' && (
                  <SelectInput
                    name='mentor'
                    label='Mentor'
                    fullWidth
                    size='small'
                  >
                    {mentors.data.data.data.map((mentor) => {
                      return (
                        <MenuItem key={mentor._id} value={mentor._id}>
                          {mentor.name}
                        </MenuItem>
                      );
                    })}
                  </SelectInput>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {coaches.status === 'success' && (
                  <SelectInput
                    name='coach'
                    label='Coach'
                    fullWidth
                    size='small'
                  >
                    {coaches.data.data.data.map((coach) => {
                      return (
                        <MenuItem key={coach._id} value={coach._id}>
                          {coach.name}
                        </MenuItem>
                      );
                    })}
                  </SelectInput>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {areas.status === 'success' && (
                  <SelectInput name='area' label='Area' fullWidth size='small'>
                    {areas.data.data.data.map((area) => {
                      return (
                        <MenuItem key={area._id} value={area._id}>
                          {area.title}
                        </MenuItem>
                      );
                    })}
                  </SelectInput>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <SelectInput
                  name='status'
                  label='Status'
                  fullWidth
                  size='small'
                >
                  <MenuItem value='Active'>Active</MenuItem>
                  <MenuItem value='On Hold'>On Hold</MenuItem>
                  <MenuItem value='Inactive'>Inactive</MenuItem>
                  <MenuItem value='Disabled'>Disabled</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  name='completionStatus'
                  label='Completion Status'
                  fullWidth
                  size='small'
                >
                  <MenuItem value='Not Applicable'>Not Applicable</MenuItem>
                  <MenuItem value='Merit'>Merit</MenuItem>
                  <MenuItem value='Completion'>Completion</MenuItem>
                </SelectInput>
              </Grid>

              <Grid item xs={12} sm={6}>
                {companies.status === 'success' && (
                  <SelectInput
                    size='small'
                    name='company2'
                    label='Second Company'
                    fullWidth
                  >
                    <MenuItem value={null}>Select Second Company</MenuItem>
                    {companies.data.data.data.map((company) => {
                      return (
                        <MenuItem key={company._id} value={company._id}>
                          {company.name}
                        </MenuItem>
                      );
                    })}
                  </SelectInput>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {mentors.status === 'success' && (
                  <SelectInput
                    name='mentor2'
                    label='Second Mentor'
                    fullWidth
                    size='small'
                  >
                    <MenuItem value={null}>Select Second Mentor</MenuItem>
                    {mentors.data.data.data
                      .filter((myMentor) => {
                        return myMentor._id !== values.mentor;
                      })
                      .map((mentor) => {
                        return (
                          <MenuItem key={mentor._id} value={mentor._id}>
                            {mentor.name}
                          </MenuItem>
                        );
                      })}
                  </SelectInput>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {coaches.status === 'success' && (
                  <SelectInput
                    name='coach2'
                    label='Second Coach'
                    fullWidth
                    size='small'
                  >
                    <MenuItem value={null}>Select Second Coach</MenuItem>
                    {coaches.data.data.data
                      .filter((mycoach) => {
                        return mycoach._id !== values.coach;
                      })
                      .map((coach) => {
                        return (
                          <MenuItem key={coach._id} value={coach._id}>
                            {coach.name}
                          </MenuItem>
                        );
                      })}
                  </SelectInput>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  name='certificateUrl'
                  label='Certificate Logo'
                  fullWidth
                >
                  <MenuItem value=''>Select certificate logo</MenuItem>
                  <MenuItem value='https://res.cloudinary.com/belong/image/upload/v1639047094/uploaded_resources/elearning-landing_moy0vz.png'>
                    Collegify
                  </MenuItem>
                  <MenuItem value='https://res.cloudinary.com/belong/image/upload/v1647273254/uploaded_resources/bbxmxctgha3woscmvp36_m92gkv.png'>
                    Jamboree
                  </MenuItem>
                  <MenuItem value='https://res.cloudinary.com/belong/image/upload/v1718699401/uploaded_resources/unnamed_zlfaqr.jpg'>
                    Yocket
                  </MenuItem>
                </SelectInput>
              </Grid>
            </Grid>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Add User
              </LoadingButton>
            </Stack>
            <Typography mt={2} textAlign='center'>
              {serverResponse}
            </Typography>
          </Form>
        )}
      </Formik>
      <Grid container spacing={1} mb={3}>
        <Grid item xs={6}>
          <UploadCsv
            batchData={batchData}
            findEmailInBatch={findEmailInBatch}
            mydata={mydata}
          />
        </Grid>
        <Grid item xs={6}>
          <AddCertificateScore batchId={batchData._id} />
        </Grid>
      </Grid>
      <ShowUser
        batchId={batchData._id}
        batchUser={batchData.users}
        updateUserId={updateUserId}
      />
    </div>
  );
};

export default AddNewUser;
