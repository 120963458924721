import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow, Button, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Snackbar from '../../../misc/Snackbar';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import Prompt from '../../../misc/Prompt';
import { useQueryClient } from 'react-query';
import DeleteIcon from '@mui/icons-material/Delete';

function QuestionBody({ row, setQuestionToEdit }) {
  const queryClient = useQueryClient();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const deleteQuestion = (id) => {
    setSnackbarText('Deleting Question...');
    setSnackbarSeverity('warning');
    setSnackbarOpen(true);

    axios
      .delete(API_URL + '/question', {
        data: { questionId: id },
      })
      .then((res) => {
        setSnackbarText('Question has been deleted');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        queryClient.invalidateQueries('fetchQuestion');
      })
      .catch((res) => {
        setSnackbarText('Something went wrong, Try after some Time');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };
  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell align='left'>{row.question} </TableCell>
        <TableCell align='left'>
          <Chip
            color={`${
              row.type === 'DOMAIN'
                ? 'primary'
                : row.type === 'MASTERCLASS'
                ? 'secondary'
                : 'warning'
            }`}
            size='small'
            label={row.type}
          />
        </TableCell>
        <TableCell align='left'>{row.domain && row.domain.title} </TableCell>
        <TableCell align='center'>
          {row.type !== 'B2C DOMAIN' && (
            <Button
              onClick={() => {
                setQuestionToEdit(row);
              }}
            >
              <EditIcon sx={{ color: 'primary.main' }} />{' '}
            </Button>
          )}
        </TableCell>

        <TableCell align='center'>
          {' '}
          <Prompt
            triggerComponent={
              <IconButton size='small' color='error' aria-label='delete'>
                <DeleteIcon />
              </IconButton>
            }
            title={'Are you sure to delete this Question?'}
            description={'The action cannot be undone'}
            cancelText={'cancel'}
            confirmText={'DELETE'}
            handlConfirm={() => {
              deleteQuestion(row._id);
            }}
            handleCancel={() => {}}
          ></Prompt>
        </TableCell>
      </TableRow>
      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </>
  );
}

export default QuestionBody;
