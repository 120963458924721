import { Navigate, useRoutes } from 'react-router-dom';
import { useEffect } from 'react';
// layouts
import DashboardLayout from './components/layouts/dashboard';
import LogoOnlyLayout from './components/layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
// import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Partner from './components/_dashboard/stakeholders/partner';
import Companies from './components/_dashboard/stakeholders/companies';
import Roles from './components/_dashboard/ops/roles';
import Invoice from './components/_dashboard/ops/invoice';
import Mentor from './components/_dashboard/stakeholders/mentor';
import Coach from './components/_dashboard/stakeholders/coach';
import Faculty from './components/_dashboard/stakeholders/faculty';
import Applications from './components/_dashboard/ops/applications';
import Batch from './components/_dashboard/vga/batch';
import Team from './pages/Team';
import Template from './components/_dashboard/vga/templates';
import Amplify, { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { fetchTeamDetails, signIn } from './redux';
import Masterclass from './components/_dashboard/ops/masterclass';
import Areas from './components/_dashboard/pod/areas';
import Categories from './components/_dashboard/pod/categories';
import Programs from './components/_dashboard/pod/programs';
import Industries from './components/_dashboard/pod/industries';
import Skills from './components/_dashboard/pod/skills';
import Questions from './components/_dashboard/pod/questions';
import B2cQuestions from './components/_dashboard/pod/b2cQuestions';
import Capstones from './components/_dashboard/pod/capstones';
import SuperCapstones from './components/_dashboard/pod/supercapstones';

import B2cUsers from './components/_dashboard/ops/b2cUsers';
import AllUsers from './components/_dashboard/reporting/users';
import CapstoneReporting from './components/_dashboard/reporting/capstone';
import AllAdmin from './components/_dashboard/stakeholders/allAdmin';
import TaskTemplates from './components/_dashboard/vga/taskTemplates';
import BootCampReporting from './components/_dashboard/reporting/bootcamp';
import SendEmail from './components/_dashboard/ops/emails';
import QuizReset from './components/_dashboard/ops/quizReset';
import GsapReporting from './components/_dashboard/reporting/gsap';
import BootcampMay23 from './components/_dashboard/reporting/bootcampMay23';
import OfflineBootcamp from './components/_dashboard/reporting/offlineBootcamp';
import AicReporting from './components/_dashboard/reporting/aicReporting';
import AicAssignment from './components/_dashboard/aic/assignment';
import AicTeams from './components/_dashboard/aic/team';
import Goacb from './components/_dashboard/reporting/goacb';
import FsdBootcamp from './components/_dashboard/reporting/fsdBootcamp';
import JupiterEvent from './components/_dashboard/reporting/jupiterEvent';
import BatchNps from './components/_dashboard/vga/nps';
import ErQuestions from 'components/_dashboard/pod/erquestions';
// ----------------------------------------------------------------------

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-2',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-2_h5OexVjb0',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '20738icdfn538kieuetqrajoj2',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // (optional) - Hosted UI configuration
    oauth: {
      domain: 'belong.auth.us-east-2.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://dashboard.belong.education',
      redirectSignOut: 'https://platform.deviare.co.za',
      clientId: '20738icdfn538kieuetqrajoj2',
      responseType: 'token',
    },
  },
});

const Router = ({ fetchTeamDetails, authStatus, signIn }) => {
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        localStorage.setItem(
          'authorization',
          user.signInUserSession.accessToken.jwtToken
        );
        fetchTeamDetails();
        signIn();
      })
      .catch(() => {
        console.log('Not signed in');
      });
  }, [fetchTeamDetails, signIn]);

  return useRoutes([
    {
      path: '/',
      element: authStatus ? <DashboardLayout /> : <Navigate to='/login' />,
      children: [
        { element: <Navigate to='/admin' replace /> },
        { path: 'admin', element: <DashboardApp /> },

        {
          path: 'ops',
          children: [
            { element: <Navigate to='/ops/roles' replace /> },
            { path: 'roles', element: <Roles /> },
            { path: 'invoice', element: <Invoice /> },
            { path: 'applications', element: <Applications /> },
            { path: 'masterclass', element: <Masterclass /> },
            { path: 'b2cusers', element: <B2cUsers /> },
            { path: 'email', element: <SendEmail /> },
            { path: 'quiz-reset', element: <QuizReset /> },
          ],
        },
        {
          path: 'stakeholders',
          children: [
            { path: 'partner', element: <Partner /> },
            { path: 'companies', element: <Companies /> },
            { path: 'mentors', element: <Mentor /> },
            { path: 'coach', element: <Coach /> },
            { path: 'faculty', element: <Faculty /> },
            { path: 'alladmin', element: <AllAdmin /> },
          ],
        },
        {
          path: 'vga',
          children: [
            { element: <Navigate to='/vga/templates' replace /> },
            { path: 'templates', element: <Template /> },
            { path: 'task-templates', element: <TaskTemplates /> },
            { path: 'nps', element: <BatchNps /> },
          ],
        },
        {
          path: 'vga',
          children: [
            { element: <Navigate to='/vga/batch' replace /> },
            { path: 'batch', element: <Batch /> },
            { path: 'event', element: <Batch /> },
            { path: 'assignment', element: <Batch /> },
            { path: 'query', element: <Batch /> },
            { path: 'feedback', element: <Batch /> },
          ],
        },
        {
          path: 'pod',
          children: [
            { element: <Navigate to='/pod/areas' replace /> },
            { path: 'areas', element: <Areas /> },
            { path: 'categories', element: <Categories /> },
            { path: 'programs', element: <Programs /> },
            { path: 'industries', element: <Industries /> },
            { path: 'skills', element: <Skills /> },
            { path: 'questions', element: <Questions /> },
            { path: 'b2cquestions', element: <B2cQuestions /> },
            { path: 'capstones', element: <Capstones /> },
            { path: 'supercapstones', element: <SuperCapstones /> },
            { path: 'erquestions', element: <ErQuestions /> },
          ],
        },
        {
          path: 'reports',
          children: [
            { element: <Navigate to='/reports/users' replace /> },
            { path: 'users', element: <AllUsers /> },
            { path: 'capstone', element: <CapstoneReporting /> },
            { path: 'bootcamp', element: <BootCampReporting /> },
            { path: 'gsap', element: <GsapReporting /> },
            { path: 'bootcamp23', element: <BootcampMay23 /> },
            { path: 'offline-bootcamp', element: <OfflineBootcamp /> },
            { path: 'aic-report', element: <AicReporting /> },
            { path: 'goacb', element: <Goacb /> },
            { path: 'fsd-bootcamp', element: <FsdBootcamp /> },
            { path: 'jupiter', element: <JupiterEvent /> },
          ],
        },
        {
          path: 'aic',
          children: [
            { element: <Navigate to='/pod/team' replace /> },
            { path: 'team', element: <AicTeams /> },
            { path: 'assignment', element: <AicAssignment /> },
          ],
        },
        { path: 'team', element: <Team /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to='/dashboard/app' /> },
        { path: '*', element: <Navigate to='/404' /> },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
};

const mapStateToProps = (state) => {
  return {
    authStatus: state.auth.authStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchTeamDetails: (token) => dispatch(fetchTeamDetails(token)),
    signIn: () => dispatch(signIn()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
