import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { supabase } from 'links/supabaseConfig';
import React, { useEffect, useState } from 'react';
import UserDetails from './UserDetails';

const bootcapmpData = [
  {
    id: 4,
    name: 'IMPB',
  },
  {
    id: 5,
    name: 'FreeCode Bootcamp',
  },
];

const Index = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bootcampId, setBootcampId] = useState(4);

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('full_stack_users')
        .select()
        .match({ bootcamp_id: bootcampId });

      if (fetchedData) {
        setAllUsers(fetchedData);
        setLoading(false);
      }
    };

    setDataFn();
  }, [bootcampId]);

  return (
    <div>
      <FormControl>
        <InputLabel id='demo-simple-select-label'>Select Bootcamp</InputLabel>
        <Select
          sx={{ maxWidth: 350, minWidth: 300 }}
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={bootcampId}
          label='Select Partner'
          onChange={(e) => setBootcampId(e.target.value)}>
          {bootcapmpData.map((el) => {
            return (
              <MenuItem key={el.id} value={el.id}>
                {el.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {loading && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'>
          <CircularProgress />
        </Box>
      )}
      {allUsers.length && <UserDetails allUsers={allUsers} />}
    </div>
  );
};

export default Index;
